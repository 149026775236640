import {
    GRAPHIC_INFORMATION_STATUS_UPDATE,
    PRODUCT_OPTION_STATUS_UPDATE,
    COLOR_MATCHING_STATUS_UPDATE,
    GRAPHICS_FILE_STATUS_UPDATE,
    SAVE_GRAPHICS_FORM_DATA,
    GRAPHICS_ID_UPDATE,
    GRAPHICS_DATA_LOADED,
    GRAPHICS_DATA_LOADING,
    GRAPHICS_DATA_LOADING_ERROR,
    GRAPHICS_RESET_FILE,
    GRAPHICS_DATA_RESET_STATE,
    GRAPHICS_UPDATE_EXISTING_FILES
} from '../actions/action-types';

export enum GraphicsStatus {
    Draft = 'DRAFT',
    ArtworkReceived = 'ART_REC',
    QuoteWaiting = 'QOT_APPRVL',
    ProofCreating = 'PRF_INPRGRS',
    ProofWaiting = 'PRF_APPRVL',
    PilotScheduled = 'PLT_SCHLD',
    StandardWaiting = 'PLT_APPRVL',
    GraphicsComplete = 'GRPHC_CMPL',
    ImageNeeded = 'IMAGE_NEEDED',
    ImageStaged = 'IMAGE_STAGED',
    UnderReview = 'UNDER_REVIEW',
    UploadError = 'UPLOAD_ERROR',
    Obsolete = 'OBS',
    ReadyToOrder = 'RTO',
    Platemaking = 'PLATES',
    ReadyToManufacture = 'RTM',
    OnHold = 'HOLD',
    Cancelled = 'CANC'
}

export enum EmeaGraphicsStatus {
    DesignSubmitted = 'DSAR',
    ArtWorkReceived = 'ARFC',
    FeasibilityCheckOnHold = 'FCOH',
    FeasibilityCheckComplete = 'FCCI',
    LayoutDevelopmentInProgress = 'LDIP',
    LayoutDevelopmentOnHold = 'LDOH',
    LayoutReadyForApproval = 'LPRA',
    LayoutApproved = 'LAPP',
    ColourDevInProgress = 'CDIP',
    ColourDevOnHold = 'CDOH',
    ColourReadyForApproval = 'CTRA',
    ColoursApproved = 'CAPP',
    ProofingInProgress = 'PIPR',
    ProofCanReady = 'PCRA',
    CompleteApproved = 'DDCA',
    CompletePending = 'DDCC',
    ProofCanApproved = 'PAPP'
}

export enum GraphicIntakeRequestType {
    New = 'New',
    Copy = 'Copy',
    Existing = 'Existing',
    Revision = 'Revision',
    Promo = 'Promo'
}

// Based on the state of the form
export enum GraphicsState {
    Draft,
    InProgress,
    Completed
}

export enum SubmissionType {
    Submit,
    Save
}

export enum ApproverType {
    Billing = 'Billing',
    ArtworkContent = 'Artwork/Content',
    PilotCanColor = 'Pilot Can/Color',
    LiquidSamples = 'Liquid Samples'
}

export interface Approver {
    id?: string;
    approverName?: string;
    approverEmail?: string;
    approverAddress?: string;
    approverAddressStreet?: string;
    approverAddressCity?: string;
    approverAddressState?: string;
    approverAddressPostalCode?: string;
    approverAddressCountry?: string;
    approverPhoneNumber?: string;
    approverType?: ApproverType[];
}
export interface GraphicsDetailStatus {
    statusId?: number;
    status?: string;
    iterationNumber?: number;
    iterationDate?: Date;
    displayStatus?: string;
}

export interface FileInfo {
    originalFileName?: string;
    fileName?: string;
    submitDate?: string;
}
export interface Graphics {
    // Graphics Api Id
    graphicsId?: string;
    // Ball Assigned Graphics #
    graphicsNumber?: string;
    requestType?: string;
    state?: GraphicsState | string;
    region?: Region;
    status?: GraphicsStatus;
    displayStatus?: string;
    revisionGraphicName?: string;
    revisionGraphicNumber?: string;
    vendorName?: string;
    // ship to account number
    shipToAddress?: string[];
    submitter?: string;
    conversionType?: string;
    requestName: string;
    requestedByDate?: Date;
    hardConversionDate?: Date;
    fileName?: string[];
    images?: File[];
    shortTerm?: string;
    shortTermStartDate?: Date;
    shortTermEndDate?: Date;
    customerName?: string;
    //customer account number
    customerAccount?: string;
    fullName?: string;
    phoneNumber?: string;
    email?: string;
    billToAddress?: string;
    approvers?: Approver[];
    customerGraphicsID?: string;
    customerSKU?: string;
    upc?: string;
    // doesn't send actual PC, but readable product label
    productCode?: string;
    specialties?: boolean;
    baseCoatRequired?: boolean;
    ink?: string;
    effect?: string;
    overvarnish?: string;
    colorMatching?: boolean;
    colorComment?: string;
    dateCode?: string;
    createdDate?: Date;
    statusDate?: Date;
    graphicSpecialist?: string;
    graphicSpecialistEmail?: string;
    actionCode?: string;
    neckSize?: string;
    fileComment?: string;
    beverageType?: string;
    type?: SubmissionType;
    // Graphic bpa fields
    productPasteurisation?: string;
    bpaFree?: boolean;
    bpaniId?: boolean;
    fileInfo?: FileInfo[];
    deletedFiles?: string[];
    pendingICWeight?: boolean;
    graphicCurrentStatuses?: GraphicsDetailStatus[];
}

export interface EmeaGraphics extends Graphics {
    // General Info
    contractFiller?: string;
    brandOwner?: string;
    canSize?: string;
    canType?: string;
    metalType?: string;
    // Design Info
    designPoNo?: string;
    existing?: string;
    designName?: string;
    newCustRefNum?: string;
    oldCustRefNum?: string;
    barcode?: string;
    barcodeBarsColor?: string;
    barcodeInfillColor?: string;
    specialFinish?: string;
    // Color Info
    basecoatColour?: string;
    specialInk?: string;
    colourTarget?: string;
    // Market Info
    distributeCountries?: string[];
    depositScheme?: string;
    recycleMark?: boolean;
    glnNumber?: string;
    asiCertified?: boolean;
    // Product Quality
    productContent?: string;
    productFormulation?: string;
    productPasteurisation?: string;
    refDesignNum?: string;
    bpaFree?: boolean;
    bpaniId?: boolean;
    // Approval Info
    proofSession?: boolean;
    attendProduction?: boolean;
    // Additional Remarks
    additionalRemarks?: string;
    designId?: string;
    graphicCoordinatorName?: string;
    graphicCoordinatorEmail?: string;
    graphicCurrentStatuses?: GraphicsDetailStatus[];
    graphicStatusHistory?: GraphicsDetailStatus[];
    imageRequired?: boolean;
}

// 'NA' | 'SA' | 'EU';
export enum Region {
    NA = 'NA',
    SA = 'SA',
    EU = 'EU'
}

export interface GraphicsLoadStatus {
    draft: boolean;
    inProgress: boolean;
    completed: boolean;
}
export interface GraphicIntakeState {
    loading: boolean;
    error: string;
    graphics?: Graphics;
    images?: File[];
    fileName?: string;
    graphicsFileValid: boolean;
    colorMatchingValid: boolean;
    productOptionsValid: boolean;
    graphicsInformationValid: boolean;
}

const initialState: GraphicIntakeState = {
    loading: false,
    error: '',
    graphics: { requestName: '' },
    graphicsInformationValid: false,
    productOptionsValid: false,
    colorMatchingValid: false,
    graphicsFileValid: false
};

const graphicIntake = (state: GraphicIntakeState = initialState, action: any) => {
    switch (action.type) {
        case GRAPHIC_INFORMATION_STATUS_UPDATE:
            return {
                ...state,
                graphicsInformationValid: action.valid
            };
        case PRODUCT_OPTION_STATUS_UPDATE:
            return {
                ...state,
                productOptionsValid: action.valid
            };
        case COLOR_MATCHING_STATUS_UPDATE:
            return {
                ...state,
                colorMatchingValid: action.valid
            };
        case GRAPHICS_FILE_STATUS_UPDATE:
            return {
                ...state,
                images: action.files
            };
        case GRAPHICS_RESET_FILE:
            return {
                ...state,
                images: null,
                fileName: null
            };
        case SAVE_GRAPHICS_FORM_DATA:
            return {
                ...state,
                graphics: action.graphics
            };
        case GRAPHICS_ID_UPDATE:
            return {
                ...state,
                graphics: {
                    ...state,
                    graphicsId: action.id
                }
            };
        case GRAPHICS_DATA_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                graphics: action.graphics
            };
        case GRAPHICS_DATA_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined
            };
        case GRAPHICS_DATA_LOADING_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error
            };
        case GRAPHICS_DATA_RESET_STATE:
            return {
                ...initialState
            };
        case GRAPHICS_UPDATE_EXISTING_FILES:
            return {
                ...state,
                graphics: {
                    ...state.graphics,
                    fileInfo: action.updatedFiles,
                    deletedFiles: action.deletedFiles
                }
            };
        default:
            return state;
    }
};

export default graphicIntake;
