import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Permissions, UserPermissionDto } from '../../store/reducers/auth';
import {
    GetUserPermissionsResponse,
    PermissionChangeItem,
    UserAdminUsers,
    UserAdminPendingRegistrations,
    UserAdminPendingRequests,
    UserAdminUserPermissions,
    UserPermissionChange,
    UserPermissionChanges
} from '../../store/reducers/user-admin';
import { DashboardElement } from '../../store/reducers/user-configuration';
import { Persona } from '../auth/useSecurity';
import { createAxiosHeader } from '../helpers/axios-helpers';

interface UserDetails {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    login?: string;
    region?: string;
    language?: string;
    company?: string;
}

interface LeadDetails {
    firstName: string;
    lastName: string;
    email: string;
    region: string;
    language: string;
    company: string;
    areaOfInterest: string;
    beverageCategory: string;
    street?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    additionalComments: string;
    canningLine: string;
    packageType: string[];
    expectedQuantity: string;
    size: string[];
    yearInBusiness: string;
    numberOfEmployees: string;
    estimatedAnnualRevenue: string;
    hasBeverageRetail: string;
    coPackerName?: string;
    title?: string;
    leadSource?: string;
    fillingCountry?: string;
    fillingCity?: string;
    emailOptOut?: boolean;
}

interface GetRegistrationResponse {
    registrationId: number;
    userName: string;
}

interface UserConfigurationRequest {
    username: string;
    dashboardPositions: Array<DashboardElement>;
}
interface UserConfigurationResponse extends UserConfigurationRequest {}

interface TradeItEmailResponse {
    accountFrom: string;
    accountFromEmails: string[];
    accountTo: string;
    accountToEmails: string[];
}

export interface CompleteForwardedRegistrationRequest {
    user: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        login: string;
        region: string;
        language: string;
        company: string;
        primaryPhone: string;
        securityQuestion: string;
        securityAnswer: string;
    };
}

export default class UserService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getPermissions(userInfo: UserInfo, axiosConfig: AxiosRequestConfig | undefined) {
        return Axios.get<Permissions>(
            `${process.env.REACT_APP_USER_API}/user/${userInfo?.preferred_username}/permissions`,
            axiosConfig
        );
    }

    static register(user: UserDetails, captchaValue: string, lead?: LeadDetails) {
        const createUserRequest = {
            user: user,
            lead: lead
        };

        const createUser = Axios.post(
            `${process.env.REACT_APP_USER_API}/user/registration`,
            createUserRequest,
            {
                headers: { CAPTCHA_VALUE: captchaValue }
            }
        );

        return createUser;
    }

    static update(
        userInfo: UserInfo,
        newUserDetails: UserDetails,
        axiosConfig?: AxiosRequestConfig
    ) {
        const userRequestPayload = {
            user: newUserDetails
        };

        return Axios.put(
            `${process.env.REACT_APP_USER_API}/user/profile/${userInfo.preferred_username}`,
            userRequestPayload,
            axiosConfig
        ).catch(() => {});
    }

    static getUserPermissions = (userEmail: string, accessToken: string) => {
        return Axios.get<UserAdminUserPermissions>(
            `${process.env.REACT_APP_USER_API}/admin/user/${userEmail}`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static getAdminUsers = (accessToken: string, status: string) => {
        const baseUrl = `${process.env.REACT_APP_USER_API}/admin/user`;
        const urlContent = `?status=${status}`;
        const requestUrl = baseUrl + urlContent;

        return Axios.get<UserAdminUsers>(requestUrl, UserService.createConfig(accessToken)).catch(
            () => {}
        );
    };

    static disableAdminUser = (userEmail: string, accessToken: string) => {
        return Axios.delete<UserAdminUserPermissions>(
            `${process.env.REACT_APP_USER_API}/admin/user/${userEmail}/permissions`,
            UserService.createConfig(accessToken)
        );
    };

    static resendPendingRegistrationRequest = (accessToken: string, registrationId: string) => {
        return Axios.put(
            `${process.env.REACT_APP_USER_API}/admin/registrations/${registrationId}/resendEmail`,
            [],
            UserService.createConfig(accessToken)
        );
    };

    static getPendingRegistrations = (accessToken: string) => {
        return Axios.get<UserAdminPendingRegistrations>(
            `${process.env.REACT_APP_USER_API}/admin/registrations`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    /**
     *
     * @param accessToken
     * @param userEmail
     * @param existing Boolean.  Set to TRUE if the user has existing permissions for their account.
     * @param request
     */
    static updateUserPermissions = (accessToken: string, permissions: UserPermissionDto[]) => {
        const url = `${process.env.REACT_APP_USER_API}/admin/user/permissions`;
        return Axios.put<GetUserPermissionsResponse>(
            url,
            { permissions },
            UserService.createConfig(accessToken)
        );
    };

    static getPendingRequests = (accessToken: string) => {
        return Axios.get<UserAdminPendingRequests>(
            `${process.env.REACT_APP_USER_API}/admin/permissionChanges`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static getPendingRequest = (accessToken: string, id: string) => {
        return Axios.get<UserPermissionChange>(
            `${process.env.REACT_APP_USER_API}/admin/permissionChanges/${id}`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static rejectUserPermissionChange = (accessToken: string, id: string) => {
        return Axios.delete<UserAdminPendingRequests>(
            `${process.env.REACT_APP_USER_API}/admin/permissionChanges/${id}`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static approveUserPermissionChange = (accessToken: string, change: UserPermissionChange) => {
        return Axios.put<UserPermissionChange>(
            `${process.env.REACT_APP_USER_API}/admin/permissionChanges/${change.permissionChangeId}`,
            change,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static getPermissionChangesForUser = (accessToken: string) => {
        return Axios.get<UserAdminPendingRequests>(
            `${process.env.REACT_APP_USER_API}/user/permissionChanges`,
            UserService.createConfig(accessToken)
        );
    };

    /**
     * @param accessToken
     * @param userEmail
     * @param request
     */
    static submitAccessRequest = (
        accessToken: string,
        userEmail: string,
        permissions: PermissionChangeItem[],
        comments?: string
    ) => {
        const url = `${process.env.REACT_APP_USER_API}/user/permissionChanges`;
        const requestBody = {
            userName: userEmail,
            comments,
            permissionChangeItems: permissions
        };
        return Axios.post<UserPermissionChanges>(
            url,
            requestBody,
            UserService.createConfig(accessToken)
        );
    };

    static getRegistration(hash: string): Promise<GetRegistrationResponse> {
        const url = `${process.env.REACT_APP_USER_API}/user/registrations?referenceId=${hash}`;

        return Axios.get<GetRegistrationResponse>(url).then((resp) => resp.data);
    }

    static resubmitPermissionRequest = (accessToken: string, id: Number) => {
        return Axios.put<UserPermissionChange>(
            `${process.env.REACT_APP_USER_API}/user/permissionChanges/${id}/resendEmail`,
            [],
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static cancelPermissionRequest = (accessToken: string, id: Number) => {
        return Axios.delete<UserPermissionChange>(
            `${process.env.REACT_APP_USER_API}/user/permissionChanges/${id}`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static completeForwardedRegistration(
        registrationId: number,
        payload: CompleteForwardedRegistrationRequest,
        captchaValue: string
    ): Promise<any> {
        const url = `${process.env.REACT_APP_USER_API}/user/registration/${registrationId}`;

        return Axios.put<any>(url, payload, {
            headers: { CAPTCHA_VALUE: captchaValue }
        }).then((resp) => resp.data);
    }

    static sendDeletePermissionEmail = (accessToken: string) => {
        return Axios.delete<any>(
            `${process.env.REACT_APP_USER_API}/user/permissions/${Persona.JoiningTermsAndConditions}`,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static getUserConfiguration = (accessToken: string) => {
        return Axios.get<UserConfigurationResponse>(
            `${process.env.REACT_APP_USER_API}/users/configurations`,
            UserService.createConfig(accessToken)
        ).then((resp) => resp.data);
    };

    static updateUserConfiguration = (
        accessToken: string,
        userName: string,
        dashboardPositions: Array<DashboardElement>
    ) => {
        const payload: UserConfigurationRequest = {
            username: userName,
            dashboardPositions
        };
        return Axios.put<any>(
            `${process.env.REACT_APP_USER_API}/users/configurations`,
            payload,
            UserService.createConfig(accessToken)
        ).catch(() => {});
    };

    static resetUserPassword = (email: string): Promise<AxiosResponse> => {
        // make sure email is uri encoded
        return Axios.get<any>(`${process.env.REACT_APP_USER_API}/user/reset-password/${email}`);
    };

    static blacklistAuthToken = (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`
        };
        return Axios.put<any>(`${process.env.REACT_APP_USER_API}/user/token-blacklist`, null, {
            headers
        });
    };

    static getTradeItEmails = (
        fromAccountId: string,
        toAccountId: string,
        cultureCode: string,
        regionCode: string,
        accessToken: string
    ): Promise<AxiosResponse<TradeItEmailResponse>> => {
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);
        return Axios.get<TradeItEmailResponse>(
            `${process.env.REACT_APP_USER_API}/user/tradeEmails/${toAccountId}/${fromAccountId}`,
            axiosConfig
        );
    };

    static updateAdminUserStatus = (accessToken, userName: string, status: boolean) => {
        return Axios.post<UserAdminPendingRequests>(
            `${process.env.REACT_APP_USER_API}/admin/user/${userName}/updatestatus`,
            status,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
    };
}
