import React, { ReactElement, useState, createRef, useEffect, useMemo } from 'react';
import { Grid, makeStyles, Container, CircularProgress, Hidden, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../../reusable/atoms/Button';
import { useForm, FormContext } from 'react-hook-form';
import { ltBlueGrey_8, successGreen, containerMaxWidth } from '../../../themes/globalConstants';
import GraphicsInformation from './components/GraphicsInformation';
import ProductOptions from './components/ProductOptions';
import ColorMatching from './components/ColorMatching';
import UploadGraphics from './components/UploadGraphics';
import { useTypedSelector } from '../../../store/reducers/reducer';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Prompt, useHistory } from 'react-router';
import { saveFormData, resetFile } from '../../../store/actions/graphic-intake';
import {
    Approver,
    GraphicIntakeRequestType,
    Graphics,
    Region
} from '../../../store/reducers/graphic-intake';
import GraphicsService from '../../../utility/services/graphics-service';
import CancelProgressModal from '../../reusable/molecules/CancelProgressModal';
import SaveConfirmedModal from '../../reusable/molecules/SaveConfirmedModal';
import { setGraphicsID, getGraphic } from '../../../store/actions/graphic-intake';
import { useDispatch } from 'react-redux';
import { Activity } from '../../../utility/auth/useSecurity';
import { getUserCustomerAccounts } from '../../../store/actions/customer-context';
import { Account, CustomerContextState } from '../../../store/reducers/customer-context';
import { isDateSameOrAfter } from '../../../utility/helpers/date-helpers';
import { AuthState } from '../../../store/reducers/auth';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import {
    getGraphicsApproverArray,
    getCompanyList,
    isGraphicsVendor
} from '../../../utility/helpers/graphic-helpers';
import { RouterConfirmationOverride } from '../../../App';
import { selectIsImpersonation } from '../../../store/selectors';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { GRAPHICS_DATA_RESET_STATE } from '../../../store/actions/action-types';
import AccountService from '../../../utility/services/account-service';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    buttonContainer: {
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    vendorButtons: {
        justifyContent: 'flex-end'
    },
    customerButtons: {
        justifyContent: 'flex-start'
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function GraphicsIntakePage(): ReactElement {
    const { id } = useParams<DefaultQueryParams>();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isSubmittingDraft, setIsSubmittingDraft] = useState<boolean>(false);
    const [submitDraftClicked, setSubmitDraftClicked] = useState<boolean>(false);
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [onDraftSaved, setOnDraftSaved] = useState<boolean>(false);
    const [colorMatching, setColorMatching] = useState<boolean>(false);
    const [specialties, setSpecialties] = useState<boolean>(false);
    const [baseCoatRequired, setBaseCoatRequired] = useState<boolean>(false);
    const [editDraft, setEditDraft] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [companyDropdown, setCompanyDropdown] = useState<Account[]>([]);
    const [shipToLocations, setShipToLocations] = useState<Account[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [clearForm, setClearForm] = useState<boolean>(false);
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [toNavigate, setToNavigate] = useState<boolean>(false);
    const [fromCancel, setFromCancel] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);

    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { accessToken, userInfo, permissions } = useTypedSelector((state) => state.auth);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const [isVendor, setIsVendor] = useState<boolean>(false);
    const hasImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const dateErrorMessage = t(
        'dateErrors',
        'Please ensure Requested Date, In-Retail date, and Out-of-Retail date are valid dates.'
    );
    const fileErrorMessage = t(
        'uploadFileError',
        'Please upload a valid zip file before submitting'
    );
    const apiErrorPrefix = t('intakeFailedToSave', 'Graphics intake form failed to submit, ');
    const formHasErrorsMessage = t(
        'resolveFormErrors',
        'Please resolve form errors to save a draft.'
    );
    const cantSaveDraftMessage = t(
        'cantSaveDraft',
        'Submission name, Company name, and Company Bill-To are required to save a draft.'
    );
    const networkErrorMessage = t(
        'networkError',
        'We are experiencing technical difficulties with your upload, please try again. If you continue to experience issues, files can be submitted to CORPGCS@ball.com for manual intake.'
    );
    const specialtiesErrorMessage = t(
        'specialtiesError',
        'Please select at least one coating or specialty'
    );

    const defaultValues = {
        // Graphic Information
        requestType: GraphicIntakeRequestType.New,
        requestName: '',
        conversionType: null,
        hardConversionDate: null,
        shortTerm: null,
        customerSelected: null as any,
        customerName: '',
        customerAccount: '',
        revisionGraphicName: '',
        revisionGraphicNumber: '',
        vendorName: '',
        fullName: userInfo ? userInfo.name : '',
        submitter: userInfo ? userInfo.email : '',
        phoneNumber: userInfo ? userInfo.PhoneNumber : undefined,
        approvers: [
            {
                approverName: '',
                approverEmail: '',
                approverAddressStreet: '',
                approverAddressCity: '',
                approverAddressState: '',
                approverAddressPostalCode: '',
                approverAddressCountry: '',
                approverPhoneNumber: undefined,
                approverType: []
            } as Approver
        ],
        beverageType: '',
        requestedByDate: null,
        shortTermStartDate: null,
        shortTermEndDate: null,
        billToAddress: undefined,
        shipTo: undefined,
        shipToAddress: undefined,
        // Product Options
        customerGraphicsID: '',
        customerSKU: '',
        upc: '',
        productCode: undefined,
        productPasteurisation: '',
        neckSize: undefined,
        specialties: false,
        specialtiesStr: '',
        baseCoatRequired: false,
        baseCoatRequiredStr: 'false',
        ink: '',
        effect: '',
        overvarnish: '',
        // Color Matching
        colorMatching: false,
        colorMatchingStr: '',
        colorComment: '',
        dateCode: '',
        region: Region.NA,
        graphicsId: undefined,
        fileComment: ''
    };

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues
    });

    const { isValid } = methods.formState;
    const isColorMatching = methods.watch('colorMatchingStr') === 'true';
    const isSpecialties = methods.watch('specialtiesStr') === 'true';
    const isBaseCoatRequired = methods.watch('baseCoatRequiredStr') === 'true';
    const ink = methods.watch('ink');
    const effect = methods.watch('effect');
    const overvarnish = methods.watch('overvarnish');
    const billToAddress = methods.watch('billToAddress');
    const requiredDraftInputs = ['requestName', 'billToAddress', 'customerSelected'];

    useEffect(() => {
        if (billToAddress && accessToken) {
            clearErrors();
            AccountService.getAccount(billToAddress, true, accessToken)
                .then((response) => {
                    const data = response.data;

                    const filteredSubAccounts = [
                        ...(data.account?.subAccounts.filter((a) => a.status === 'Active') || []),
                        ...(data.account?.parentAccount?.subAccounts
                            ?.filter((a) => a.status === 'Active')
                            ?.filter((a) => a.addressType !== 'CX' && a.addressType !== 'CB') || [])
                    ];

                    if (data.account.addressType === 'CX') {
                        filteredSubAccounts.push(data.account);
                    }

                    const uniqueShipTos = filteredSubAccounts
                        .filter(
                            (obj, index, self) =>
                                index === self.findIndex((el) => el.accountId === obj.accountId)
                        )
                        .sort((a, b) => a.name.localeCompare(b.name));

                    setShipToLocations(uniqueShipTos);
                })
                .catch(() => {
                    setShipToLocations([]);
                });
            if (hasImpersonation) {
                setShowImpersonationWarning(true);
            }
        } else {
            setShipToLocations([]);
        }
    }, [billToAddress, accessToken, hasImpersonation]);

    useEffect(() => {
        setColorMatching(isColorMatching);
    }, [isColorMatching]);

    useEffect(() => {
        setSpecialties(isSpecialties);
    }, [isSpecialties]);

    useEffect(() => {
        setBaseCoatRequired(isBaseCoatRequired);
    }, [isBaseCoatRequired]);

    useEffect(() => {
        if (id) {
            dispatch(getGraphic(id));
        } else {
            dispatch({ type: GRAPHICS_DATA_RESET_STATE });
        }
    }, [id, dispatch, setEditDraft]);

    useEffect(() => {
        if (id && graphicIntake.graphics.requestName !== '') {
            setEditDraft(true);
        }
    }, [id, graphicIntake.graphics]);

    useEffect(() => {
        if (permissions) {
            setIsVendor(isGraphicsVendor(auth));
            if (accounts && accounts.length > 0) {
                setCompanyDropdown(getCompanyList(accounts));
            } else {
                dispatch(getUserCustomerAccounts());
            }
        }
    }, [dispatch, auth, permissions, accounts]);

    const onSubmit = (data) => {
        setAllowNavigation(true);
        clearErrors();
        setIsSubmitting(true);
        const graphics = graphicIntake.graphics;
        let isRequestedByAfterInRetail = false;
        let isInRetailAfterOutOfRetail = false;

        if (data.shortTerm === 'Yes') {
            isRequestedByAfterInRetail = isDateSameOrAfter(
                data.requestedByDate,
                data.shortTermStartDate
            );
            isInRetailAfterOutOfRetail = isDateSameOrAfter(
                data.shortTermStartDate,
                data.shortTermEndDate
            );
        }

        //Make sure user has uploaded a valid file
        if (!graphicIntake.images?.length) {
            setErrorMessage(fileErrorMessage);
            setIsSubmitting(false);
        } else if (isRequestedByAfterInRetail || isInRetailAfterOutOfRetail) {
            const errorType = isRequestedByAfterInRetail
                ? 'shortTermStartDate'
                : 'shortTermEndDate';
            methods.setError(errorType, { type: 'manual' });
            setErrorMessage(dateErrorMessage);
            setIsSubmitting(false);
        } else if (specialties && !ink && !effect && !overvarnish) {
            setErrorMessage(specialtiesErrorMessage);
            setIsSubmitting(false);
        } else {
            if (graphics.graphicsId) {
                data.graphicsId = graphics.graphicsId;
            }
            //converting string values to booleans for API
            data.colorMatching = colorMatching;
            data.specialties = specialties;
            data.baseCoatRequired = baseCoatRequired;

            data.region = Region.NA;

            if (data.customerSelected) {
                data.customerName = data.customerSelected.name;
                data.customerAccount = data.customerSelected.accountId;
            }

            data.approvers.forEach((approver) => {
                const fullAddress =
                    approver.approverAddressStreet +
                    ', ' +
                    approver.approverAddressCity +
                    ', ' +
                    approver.approverAddressState +
                    ', ' +
                    approver.approverAddressPostalCode;
                approver.approverAddress = fullAddress;
                approver.approverAddressCountry = approver.approverAddressCountry.value;
            });

            if (!hasImpersonation) {
                GraphicsService.submitGraphicsForm(data as Graphics, accessToken)
                    .then((response) => {
                        data.graphicsId = response.data.graphicId;
                        dispatch(saveFormData(data as Graphics));
                        GraphicsService.submitGraphicsImage(
                            response.data.graphicId,
                            graphicIntake.images,
                            accessToken
                        )
                            .then((response) => {
                                data.images = graphicIntake.images;
                                dispatch(saveFormData(data as Graphics));
                                setIsSubmitting(false);
                                history.push('/graphics-intake-success');
                            })
                            .catch((error) => {
                                handleApiError(error);
                            });
                    })
                    .catch((error) => {
                        handleApiError(error);
                    });
            } else {
                setIsSubmitting(false);
                setShowImpersonationWarning(true);
            }
        }
    };

    const handleApiError = (error) => {
        let message;
        //Determine if the error is graphic upload timeout or other
        if (error.message === 'Response failed with status code 502') {
            message = t(
                'graphicUploadTimeout',
                'graphic upload timed out. Please try again later.'
            );
            setErrorMessage(`${apiErrorPrefix}, ${message}`);
        } else if (error.message === 'Network Error') {
            setErrorMessage(networkErrorMessage);
        } else {
            //If other, send it to handle the error text to be displayed
            handleApiErrorText(error);
        }
        setIsSubmitting(false);
        setAllowNavigation(false);
    };

    //If more than one specific error/rule needs to be handled from the API,
    //this can be converted to a switch.
    const handleApiErrorText = (error) => {
        const errorData = error?.response?.data?.errors;
        let message;

        if (errorData) {
            if (errorData.SpecialtiesRule) {
                message = t(
                    'requiresSpecialty',
                    'you noted your product requires a special coat or specialty. Please select your coating, finish or effect.'
                );
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            } else if (errorData.Submitter) {
                message = t('requiresSubmitter', 'please add a valid email address.');
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            } else if (errorData.BillToAddress) {
                // TODO need translate on this statement and better approaching
                message = errorData.BillToAddress[0];
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
                // INFO: Fluent validation does not have a keyword for File Size Limits information, then we
            } else if (errorData[''][0].includes('Multipart body length limit')) {
                setErrorMessage(networkErrorMessage);
            } else {
                message = t('unknownError', 'an unknown error occurred. Please try again later.');
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            }
        }
    };

    const onSaveDraft = async () => {
        await validateDraftInputs();
        setSubmitDraftClicked(true);
        clearErrorsForEmptyFieldsNotRequiredForDraft();
        const formErrors = Object.keys(methods.errors);
        clearErrors();
        if (formErrors && formErrors.length > 0) {
            if (formErrors.every((error) => requiredDraftInputs.includes(error)))
                setErrorMessage(cantSaveDraftMessage);
            else setErrorMessage(formHasErrorsMessage);

            setCancelOpen(false);
        } else {
            setIsSubmittingDraft(true);
            setAllowNavigation(true);
            clearErrors();
            const graphics = graphicIntake.graphics;
            let data = methods.getValues();
            data.colorMatching = colorMatching ?? undefined;
            data.specialties = specialties ?? undefined;
            data.baseCoatRequired = baseCoatRequired ?? false;

            data.region = Region.NA;

            if (graphics && graphics.graphicsId) {
                data.graphicsId = graphics.graphicsId;
            }

            if (data.customerSelected !== null) {
                data.customerName = data.customerSelected.name ?? '';
                data.customerAccount = data.customerSelected.accountId ?? '';
            }
            data.approvers = getGraphicsApproverArray(data);

            if (cancelOpen) {
                setCancelOpen(false);
            }

            if (!hasImpersonation) {
                GraphicsService.saveDraftGraphicsForm(data as unknown as Graphics, accessToken)
                    .then((response) => {
                        if (!graphics || !graphics.graphicsId) {
                            dispatch(setGraphicsID(response.data.graphicId));
                        }
                        setOnDraftSaved(true);
                        if (toNavigate) {
                            handleConfirmNavigation();
                        }
                    })
                    .catch((error) => {
                        handleApiError(error);
                    })
                    .finally(() => {
                        setIsSubmittingDraft(false);
                    });
            } else {
                setIsSubmittingDraft(false);
                setShowImpersonationWarning(true);
            }
        }
    };

    const clearErrorsForEmptyFieldsNotRequiredForDraft = () => {
        const inputsWithError = Object.keys(methods.errors);
        const inputKeys = Object.keys(methods.getValues());

        inputKeys.forEach((key) => {
            const value = methods.getValues()[key];
            const keyTopLevelName = key.split('[')[0];
            if (
                !requiredDraftInputs.includes(key) && // input isn't required for saving draft
                inputsWithError.includes(keyTopLevelName) && // input is in an error state
                // eslint-disable-next-line
                (value === null || value === undefined || value == '' || value.toString() === '[]') // input is blank or empty
            ) {
                methods.clearError(key);
            }
        });
    };

    const validateDraftInputs = async () => {
        methods.triggerValidation(requiredDraftInputs);
        return new Promise((r) => setTimeout(r));
    };

    const onCancel = () => {
        setCancelOpen(true);
        setFromCancel(true);
    };

    const onCloseConfirmation = () => {
        setCancelOpen(false);
        setToNavigate(false);
        setFromCancel(false);
    };

    const onDraftSavedClose = () => {
        setOnDraftSaved(false);
        setFromCancel(false);
    };

    const handleSubmitCapture = () => {
        clearErrors();
        setSubmitClicked(true);
        methods.triggerValidation();
        if (!isValid) {
            setErrorMessage(
                t(
                    'fillOutRequiredFields',
                    'All required fields must be completed prior to submitting.'
                )
            );
        }
    };

    const onDeleteCustomerProgress = () => {
        setAllowNavigation(true);
        resetForm();
        if (lastLocationPath !== '') {
            history.push(lastLocationPath);
        }
        if (fromCancel) {
            history.push('/graphics-summary');
        }
    };

    const resetForm = () => {
        methods.reset(defaultValues);
        // Reset the file content in state
        dispatch(resetFile());
        setCancelOpen(false);
        clearErrors();
        scrollToTop();
        setClearForm(!clearForm);
    };

    const clearErrors = () => {
        setErrorMessage('');
        setShowImpersonationWarning(false);
    };

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 10);
    };

    const sections = useMemo(
        () => [
            {
                header: t('graphicsSubmissionInfo', 'Graphics Submission Information'),
                icon: false
            },
            {
                header: t('productOptions', 'Product Options'),
                icon: false
            },
            {
                header: t('colorMatchingOptional', 'Color Matching'),
                icon: false
            },
            {
                header: t('uploadGraphicsAndSubmit', 'Upload Graphics & Submit'),
                icon: false
            }
        ],
        [t]
    );

    const formSections = [
        {
            section: (
                <GraphicsInformation
                    isEditDraft={editDraft}
                    graphicData={graphicIntake.graphics}
                    companyDropdown={companyDropdown}
                    shipToLocations={shipToLocations}
                    clearForm={clearForm}
                />
            )
        },
        {
            section: <ProductOptions isEditDraft={editDraft} graphicData={graphicIntake.graphics} />
        },
        {
            section: <ColorMatching isEditDraft={editDraft} graphicData={graphicIntake.graphics} />
        },
        {
            section: (
                <UploadGraphics
                    isEditDraft={editDraft}
                    graphicData={graphicIntake.graphics}
                    region={Region.NA}
                />
            )
        }
    ];
    const sectionRefs = sections.map(() => createRef<HTMLElement>());
    const header = <Trans i18nKey="formOverview">Form Overview</Trans>;
    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );

    useEffect(() => {
        if (sections[0].icon !== graphicIntake.graphicsInformationValid) {
            sections[0].icon = graphicIntake.graphicsInformationValid;
        }
        if (sections[1].icon !== graphicIntake.productOptionsValid) {
            sections[1].icon = graphicIntake.productOptionsValid;
        }
        if (sections[2].icon !== graphicIntake.colorMatchingValid) {
            sections[2].icon = graphicIntake.colorMatchingValid;
        }
        if (sections[3].icon !== graphicIntake.graphicsValid) {
            sections[3].icon = graphicIntake.graphicsValid;
        }
    }, [
        sections,
        graphicIntake.graphicsInformationValid,
        graphicIntake.productOptionsValid,
        graphicIntake.colorMatchingValid,
        graphicIntake.graphicsValid
    ]);

    useEffect(() => {
        if (allowNavigation && lastLocationPath !== '' && !isSubmitting) {
            history.push(lastLocationPath);
        }
    }, [allowNavigation, history, isSubmitting, lastLocationPath]);

    const handleBlockedNavigation = (location) => {
        setToNavigate(true);
        if (location !== history.location.pathname) setLastLocationPath(location);
        if (!allowNavigation) {
            setCancelOpen(true);
            return false;
        }

        return true;
    };

    const handleConfirmNavigation = () => {
        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            history.push(lastLocationPath);
        }
    };
    const footerActions = (
        <>
            <Grid container item xs={3} justify="space-between">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={onSaveDraft}
                    className={classes.actionBtn}
                    disabled={isSubmitting || isSubmittingDraft || showImpersonationWarning}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button>
            </Grid>
            <Grid container item xs={5} alignItems="center" justify="center">
                <ErrorAlert
                    errorMessage={errorMessage}
                    showError={(submitClicked || submitDraftClicked) && errorMessage !== ''}
                    dataTestId="validation-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={isSubmitting || isSubmittingDraft || showImpersonationWarning}
                    onClickCapture={handleSubmitCapture}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('submitGraphics', 'Submit Your Graphics'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.GraphicsIntakeForm}
            shipTos={false}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
        >
            {userInfo && (
                <FormContext {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container data-testid="graphics-intake-page">
                            <Container className={classes.container}>
                                <Grid container item xs={12}>
                                    <Grid item sm={4}>
                                        <Hidden xsDown>
                                            <Paper className={classes.paper}>
                                                <HoverNavMenu
                                                    translatedHeader={header}
                                                    readOnly={false}
                                                    sections={sections
                                                        .map((section, index) => ({
                                                            ref: sectionRefs[index],
                                                            header: section.header,
                                                            icon: section.icon,
                                                            iconSrc: icon
                                                        }))
                                                        .filter((section) => section.header)}
                                                />
                                            </Paper>
                                        </Hidden>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={8}
                                        className={classes.formSectionContainer}
                                    >
                                        {formSections.map((form, index) => {
                                            return (
                                                <section ref={sectionRefs[index]} key={index}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.formSection}
                                                    >
                                                        {form.section}
                                                    </Grid>
                                                </section>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container>
                                {(isSubmitting || isSubmittingDraft) && (
                                    <Grid container item xs={12} className={classes.spinningLoader}>
                                        <CircularProgress />
                                    </Grid>
                                )}
                            </Container>
                        </Grid>
                    </form>
                </FormContext>
            )}
            <CancelProgressModal
                open={cancelOpen}
                saveProgress={onSaveDraft}
                onCancel={onDeleteCustomerProgress}
                onClose={onCloseConfirmation}
            />
            <SaveConfirmedModal
                title={<Trans i18nKey="graphicDraftSaved">Graphic Intake Draft Saved</Trans>}
                open={onDraftSaved}
                onClose={onDraftSavedClose}
                hideDashboardBtn={isVendor}
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
        </ProcessingPageTemplate>
    );
}
